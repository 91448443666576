.footer {
  position: fixed;
  @include adaptiveValue("bottom", 50, 20);
  left: 0;
  width: 100%;
  transform: translate(0, 100%);
  opacity: 0;
  transition: all 0.3s ease 0.4s;
  .loaded & {
    opacity: 1;
    transform: translate(0px, 0px);
  }
  // .footer__container
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobileSmall) {
      flex-direction: column;
      row-gap: rem(20);
    }
  }
  // .footer__copy
  &__copy {
    flex: 0 1 rem(600);
    @include adaptiveValue("font-size", 18, 16);
    line-height: math.div(22, 18);
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    @media (max-width: $mobileSmall) {
      order: 3;
      flex: 1 1 auto;
    }
  }
  &__copy a {
    color: rgb(255, 255, 255, 0.8);
    transition: color 0.3s ease 0s;
    @media (any-hover: hover) {
      &:hover {
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
  // .footer__arrow
  &__arrow {
    @media (any-hover: hover) {
      flex: 1 1 auto;
      position: relative;
      display: block;
      height: rem(35);
      width: rem(40);
      background: url("../images/icons/arrow-down.svg") 0 0 / 100% 100%
        no-repeat;
      animation: arrowDown 2s infinite ease-in-out;
      @media (max-width: $mobileSmall) {
        order: 1;
        margin-bottom: rem(10);
      }
      @keyframes arrowDown {
        0% {
          transform: translate(0px, rem(-10));
          opacity: 0;
        }
        50% {
          opacity: 1;
        }
        100% {
          transform: translate(0px, rem(10));
          opacity: 0;
        }
      }
      @media (max-width: $mobile) {
        height: rem(22);
        width: rem(25);
      }
    }
    @media (any-hover: none) {
      display: none;
    }
  }
  // .footer__socials
  &__socials {
    flex: 0 1 rem(298);
    justify-content: flex-end;
    @media (max-width: $mobileSmall) {
      order: 2;
      flex: 1 1 auto;
    }
  }
}

.socials-footer {
  display: flex;
  column-gap: rem(20);
  // .socials-footer__link
  &__link {
    opacity: 0.5;
    transition: opacity 0.3s ease 0s;
    @media (any-hover: hover) {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}
