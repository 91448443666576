body::after {
	content: "";
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	backdrop-filter: blur(5px);

	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: all 0.3s ease 0s, z-index 0s ease 0s;
	._popup-open-purple & {
		z-index: 139;
		background: linear-gradient(105.18deg, rgba(229, 50, 242, 0.7) 0%, rgba(117, 251, 245, 0.7) 100%);
		opacity: 1;
		visibility: visible;
	}
	._popup-open & {
		z-index: 139;
		opacity: 1;
		background: #01171a;
		visibility: visible;
	}
	._popup-copy & {
		z-index: 150;
	}
}
#popup-tiles {
	z-index: 140;
	._popup-open & {
		transition: all 0s ease 0s;
		visibility: hidden;
		opacity: 0;
	}
}
#popup-copy,
#popup-order,
#popup-already-minted {
	z-index: 152;
	&::after {
		content: "";
		position: fixed;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		backdrop-filter: blur(5px);
		background: rgba(1, 23, 26, 0.7);
		pointer-events: none;
		transition: all 0.3s ease 0s, z-index 0s ease 0s;
	}
}
.popup {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	pointer-events: none;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	padding-left: rem(30);
	padding-right: rem(30);
	@include adaptiveValue("padding-top", 100, 40);
	@include adaptiveValue("padding-bottom", 100, 40);
	@media (max-width: $mobile) {
		padding-left: rem(20);
		padding-right: rem(20);
	}

	&._popup-open {
		z-index: 151;
		// transition: all 0.4s ease 0s;
		visibility: visible;
		opacity: 1;
		overflow: auto;
		pointer-events: auto;
	}
	// .popup__wrapper
	&__wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 100%;
		flex: 1 1 auto;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		// transform: scale(0.8);
		visibility: hidden;
		overflow: 0;
		transition: all 0.3s ease 0s;
		@include adaptiveValue("padding-right", 115, 50);
		._popup-open & {
			opacity: 1;
			visibility: visible;
		}
		#popup-copy &,
		#popup-order &,
		#popup-already-minted & {
			justify-content: flex-start;
		}
	}
	// .popup__content
	&__content {
		position: relative;
		max-width: rem(1400);
		margin: 0 auto;
		width: 100%;
		#popup-copy &,
		#popup-order &,
		#popup-already-minted & {
			width: auto;
		}
	}
}
.popup-actions {
	position: absolute;
	transform: translate(100%, 0);
	z-index: 2;
	height: 100%;
	right: rem(-30);
	top: 0;
	@media (max-width: $pc) {
		right: rem(-20);
	}
	@media (max-width: $mobileSmall) {
		right: rem(-10);
	}
	// .popup-actions__buttons
	&__buttons {
		position: sticky;
		@include adaptiveValue("top", -70, -30);
	}
	// .popup-actions__button
	&__button {
		background-color: rgba(255, 255, 255, 0.2);
		transition: background-color 0.2s ease 0s;
		display: flex;
		justify-content: center;
		align-items: center;
		@include adaptiveValue("width", 90, 38);
		@include adaptiveValue("height", 90, 38);
		position: relative;
		background-color: rgba(255, 255, 255, 0.2);
		&.disabled {
			pointer-events: none;
			&::after {
				content: "";
				position: absolute;
				width: calc(100% + rem(8));
				height: calc(100% + rem(8));
				top: rem(-4);
				left: rem(-4);
				background-color: rgba(0, 27, 31, 0.3);
			}
		}
		img {
			@include adaptiveValue("width", 50, 20);
			max-width: 100%;
			pointer-events: none;
		}
		&._popup-open {
			transition: background-color 0.2s ease 0s;
		}
		&:not(:last-child) {
			margin-bottom: rem(30);
		}
		@media (max-width: $mobile) {
			&:not(:last-child) {
				margin-bottom: rem(12);
			}
		}
		@media (any-hover: hover) {
			&:hover {
				background-color: rgba(255, 255, 255, 0.4);
			}
		}
		// .popup-actions__button_shadows
		&_shadows {
			position: relative;
			background-color: #01181c;
			box-shadow: 4px -4px 0px #e532f2, -4px 4px 0px #75fbf5;
			transition: box-shadow 0.2s ease 0s;

			@media (any-hover: hover) {
				&:hover {
					background-color: #01181c;
					box-shadow: 7px -7px 0px #e532f2, -7px 7px 0px #75fbf5;
				}
			}
			@media (max-width: $mobile) {
				margin-top: rem(20);
			}
			@media (max-width: $mobileSmall) {
				box-shadow: 3px -3px 0px #e532f2, -3px 3px 0px #75fbf5;
			}
		}
	}
	// .popup-actions__quantity
	&__quantity {
		position: absolute;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		@include adaptiveValue("height", 24, 14);
		@include adaptiveValue("min-width", 66, 35);
		right: 0;
		transform: translate(15%, -60%);
		background-color: #75fbf5;
		padding: 0 rem(4);
		color: #01181b;
		font-weight: 700;
		@include adaptiveValue("font-size", 16, 10);
		line-height: math.div(20, 16);
	}
}

.token-tiles {
	// .token-tiles__cards
	&__cards {
		position: relative;
		max-width: rem(1400);
		width: 100%;
	}
	&__items {
		display: grid;
		align-items: start;
		grid-template-columns: repeat(auto-fit, minmax(rem(200), 1fr));
		transform: translate(0, 100%);
		._popup-open & {
			transition: transform 0.4s ease 0s;
			transform: translate(0px, 0px);
		}
	}
}
.token-card {
	position: relative;
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transition: all 0.3s ease 0s;
		background: linear-gradient(0deg, rgba(229, 50, 242, 0.4), rgba(229, 50, 242, 0.4));
	}
	&::after {
		content: "";
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		bottom: 0;
		left: 0;
		background: linear-gradient(0deg, rgba(229, 50, 242, 0.4), rgba(229, 50, 242, 0.4));
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s ease 0s;
	}
	&_available {
		&::before {
			display: none;
		}
		&::after {
			background: linear-gradient(180deg, rgba(117, 251, 245, 0.5) 0%, #09938c 100%);
		}
	}
	// @media (any-hover: hover) {
	&:hover {
		&::after {
			opacity: 1;
			visibility: visible;
		}
		.token-card__content {
			transform: translate(0, 0);
		}
		.token-card__image-ibg {
			img {
				transform: scale(1.1);
			}
		}
	}
	// }
	// @media (any-hover: none) {
	// 	text-shadow: 1px 1px rgba(0, 0, 0, 0.3);
	// 	&::after {
	// 		height: 45%;
	// 		opacity: 0.8;
	// 		filter: blur(7.5px);
	// 		visibility: visible;
	// 	}
	// }
	// .token-card__image-ibg
	&__image-ibg {
		border: rem(0.5) inset rgba(255, 255, 255, 0.05);
		padding-bottom: 100%;
		overflow: hidden;
		img {
			transition: transform 0.4s ease 0s;
			@media (any-hover: hover) {
				will-change: transform;
			}
		}
	}
	// .token-card__info
	&__info {
		position: absolute;
		z-index: 2;
		top: rem(15);
		right: rem(15);
	}
	// .token-card__balance
	&__balance {
		@extend .token-card__read;
		font-weight: 700;
		color: #fff;
		font-size: rem(20);
		line-height: math.div(24, 20);
	}
	// .token-card__read
	&__read {
		display: flex;
		justify-content: center;
		align-items: center;
		width: rem(46);
		height: rem(46);
		border-radius: 50%;
		background: rgba(255, 255, 255, 0.2);
		&:not(:last-child) {
			margin-bottom: rem(10);
		}
		img {
			max-width: 100%;
			width: rem(25);
		}
	}
	// .token-card__content
	&__content {
		position: absolute;
		z-index: 2;
		bottom: rem(20);
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 0 rem(15);
		text-align: center;
		transition: transform 0.2s ease 0s;
		// @media (any-hover: hover) {
		transform: translate(0, 150%);
		// }
		// @media (any-hover: none) {
		// bottom: rem(20);
		// }
	}
	// .token-card__chart
	&__chart {
		@include adaptiveValue("font-size", 18, 16);
		line-height: math.div(22, 18);
		color: #fff;
		font-weight: 700;
		&:not(:last-child) {
			margin-bottom: rem(3);
		}
	}
	// .token-card__title
	&__title {
		font-weight: 500;
		@include adaptiveValue("font-size", 16, 14);
		line-height: math.div(20, 16);
		color: rgba(255, 255, 255, 0.7);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; // Кол-во строк
		-webkit-box-orient: vertical;
	}
}
//====================================================================
.chapter-popup {
	font-family: "IBM Plex Sans";
	background-color: #fff;
	@include adaptiveValue("padding-left", 100, 20);
	@include adaptiveValue("padding-right", 100, 20);
	@include adaptiveValue("padding-top", 80, 40);
	@include adaptiveValue("padding-bottom", 80, 40);
	min-height: 100vh;
	// .chapter-popup__body
	&__body {
		overflow: auto;
	}
	// .chapter-popup__title
	&__title {
		text-align: center;
		@include adaptiveValue("font-size", 72, 24);
		line-height: math.div(93, 72);
		font-weight: 500;
		color: #111111;

		&:not(:last-child) {
			margin-bottom: em(30, 72);
		}
	}
	// .chapter-popup__text
	&__text {
		@include adaptiveValue("font-size", 24, 15);
		line-height: math.div(40, 24);
		p {
			&:not(:last-child) {
				margin-bottom: rem(30);
			}
		}
	}
}
.book-popup {
	@extend .chapter-popup;
	h2 {
		@extend .chapter-popup__title;
		&:not(:first-child) {
			margin-top: em(70, 72);
		}
	}
	p {
		@include adaptiveValue("font-size", 24, 15);
		line-height: math.div(40, 24);
		&:not(:last-child) {
			margin-bottom: rem(30);
		}
	}
}
//====================================================================
.small-popup {
	background: #ffffff;
	box-shadow: 8px -8px 0px #e532f2, -8px 8px 0px #75fbf5;
	width: rem(640);
	margin: 0 auto;
	padding: rem(40) 0;
	@include adaptiveValue("padding-left", 45, 15);
	@include adaptiveValue("padding-right", 45, 15);
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (max-width: $tablet) {
		width: 100%;
		max-width: rem(640);
	}
	@media (max-width: $mobile) {
		box-shadow: 4px -4px 0px #e532f2, -4px 4px 0px #75fbf5;
	}
	// .small-popup__image-ibg
	&__image-ibg {
		max-width: rem(434);
		margin: 0 auto;
		width: 100%;
		padding-bottom: 79%;
		@media (max-width: $tablet) {
			max-width: rem(290);
		}
		&:not(:last-child) {
			margin-bottom: rem(20);
		}
		@media (max-width: $mobileSmall) {
			padding-bottom: 100%;
		}
	}
	// .small-popup__title
	&__title {
		text-align: center;
		@include adaptiveValue("font-size", 32, 18);
		line-height: math.div(39, 32);
		color: #111;
		font-weight: 600;
		&:not(:last-child) {
			margin-bottom: rem(10);
		}
	}
	// .small-popup__text
	&__text {
		font-weight: 500;
		@include adaptiveValue("font-size", 18, 16);
		line-height: math.div(19, 16);
		text-align: center;
		&:not(:last-child) {
			margin-bottom: rem(20);
		}
		@media (max-width: $mobile) {
			font-size: rem(16);
		}
	}
	// .small-popup__button
	&__button {
		margin-top: rem(10);
		max-width: rem(325);
	}
}

.duplicate {
	@extend .small-popup;
	// .duplicate__image-ibg
	&__image-ibg {
		@extend .small-popup__image-ibg;
		// .duplicate__image-ibg_contain
		&_contain {
		}
	}
	&__text {
		@extend .small-popup__text;
	}
	// .duplicate__title
	&__title {
		@extend .small-popup__title;
	}
}
