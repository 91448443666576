.header {
	position: absolute;
	@include adaptiveValue('top', 50, 20);
	left: 0;
	width: 100%;
	transform: translate(0, -100%);
	opacity: 0;
	transition: all 0.3s ease 0.2s;
	.loaded & {
		opacity: 1;
		transform: translate(0px, 0px);
	}
	// .header__container
	&__container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: rem(30);
	}
	// .header__logo
	&__logo {
		display: block;
		@include adaptiveValue('width', 106, 52);
		img {
			max-width: 100%;
		}
	}
	// .header__button
	&__button {
		@include adaptiveValue('width', 245, 155);
		@media (any-hover: hover) {
			&:hover {
				.header__address {
					display: none;
				}
				.header__disconnect {
					display: block;
				}
			}
		}
		&.shake {
			animation: shake 150ms 2 linear;
		}
		@keyframes shake {
			0% {
				transform: translate(3px, 0);
			}
			50% {
				transform: translate(-3px, 0);
			}
			100% {
				transform: translate(0, 0);
			}
		}
	}
	// .header__disconnect
	&__disconnect {
		display: none;
	}
}
