@use "sass:math";

@import "base/mixins";

// Fonts ===========================================================================================================================================================================================================================================================================
//&display=swap - добавить при подключении через плагин
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:regular,500&display=swap");

// @import "fonts/fonts";
//@import "fonts/icons";

$fontFamily: "Montserrat";
$fontSize: rem(14);

// Variables
$mainColor: #232323;

// Settings ===============================================================================================================================================================================================================================================================================================
$minWidth: 320;
$maxWidth: 1920;
$maxWidthContainer: 1820;
$containerPadding: 40;

$containerWidth: $maxWidthContainer + $containerPadding;

$pc: em($containerWidth);
$tablet: em(991.98);
$mobile: em(767.98);
$mobileSmall: em(479.98);

$responsiveType: 1;

// Zeroing and general parameters ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
@import "base/null";

body {
  overflow: hidden;
  background: radial-gradient(100% 100% at 50% 0%, #001b1f 0%, #011417 100%);
  // Scroll is blocked
  .lock & {
    overflow: hidden;
    touch-action: none;
    // new
    overscroll-behavior: none;
  }
  // The site is loaded
  .loaded & {
  }
}
// Shell ============================================================================================================================================================================================================================================================================================================================================================================================================================================
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > main {
    flex: 1 1 auto;
  }
  > * {
    min-width: 0;
  }
}
.preloader {
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(1, 23, 26, 1);
  transition: all 0.4s ease 0s;
  img {
    max-width: 100%;
  }
  .loaded & {
    opacity: 0;
    visibility: hidden;
  }
  ._transaction & {
    opacity: 1;
    visibility: visible;
    background-color: rgba(1, 23, 26, 0.7);
  }
}

@if ($responsiveType==1) {
  [class*="__container"] {
    @if ($maxWidthContainer>0) {
      max-width: rem($containerWidth);
      margin: 0 auto;
    }
    @if ($containerPadding>0) {
      @if ($maxWidthContainer>0) {
        padding: 0 rem(math.div($containerPadding, 2));
      } @else {
        @include adaptiveValue(
          "padding-left",
          math.div($containerPadding, 2),
          15
        );
        @include adaptiveValue(
          "padding-right",
          math.div($containerPadding, 2),
          15
        );
      }
    }
  }
} @else {
  [class*="__container"] {
    margin: 0 auto;
    @if ($maxWidthContainer>0) {
      max-width: rem($containerWidth);
    } @else {
      @if ($containerPadding>0) {
        padding: 0 rem(math.div($containerPadding, 2));
      }
    }
    @media (max-width: $pc) {
      max-width: rem(970);
    }
    @media (max-width: $tablet) {
      max-width: rem(750);
    }
    @media (max-width: $mobile) {
      max-width: none;
      @if ($containerPadding>0 and $maxWidthContainer>0) {
        padding: 0 rem(math.div($containerPadding, 2));
      }
    }
  }
}

// Connecting basic styles, templates (blanks) and auxiliary classes
@import "base";

// Connecting styles of general project elements
@import "common";

// Connecting styles of individual blocks
@import "header";
@import "footer";

// Connection of individual pages
@import "home";

//====================================================================