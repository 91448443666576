.main-images {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px rem(32);
	width: 100%;
	position: relative;
	overflow: hidden;
	pointer-events: none;
	// .main-images__items
	&__items {
		display: flex;
		// width: 100%;
		align-items: center;
		justify-content: center;
		perspective: 1000px;
		perspective-origin: 50% 50%;
		pointer-events: auto;
		transform: translate(0, -5%);
		transition: transform 0.3s ease 0s;
		@media (max-width: $mobileSmall) {
			transform: translate(0, -30%);
		}
		// padding-bottom: 10%;
		@media (any-hover: hover) {
			&:hover {
				transform: translate(0, -5%) scale(1.02);
				.main-images__item-ibg {
					&::after {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
	// .main-images__item-ibg
	&__item-ibg {
		position: relative;
		flex-grow: 0;
		flex-shrink: 0;
		@include adaptiveValue("width", 550, 200);
		@include adaptiveValue("flex-basis", 550, 200);
		@include adaptiveValue("height", 550, 200);
		&:not(:first-child) {
			@include adaptiveValue("margin-left", -420, -155);
		}
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(117, 251, 245, 0.4);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease 0s;
		}
		&:nth-child(1) {
			img {
				transition: all 0.4s ease 0s;
			}
		}
		&:nth-child(2) {
			img {
				transition: all 0.4s ease 0.05s;
			}
		}
		&:nth-child(3) {
			img {
				transition: all 0.4s ease 0.1s;
			}
		}
		&:nth-child(4) {
			img {
				transition: all 0.4s ease 0.15s;
			}
		}
		img {
			opacity: 0;
			transform: scale(0.5);
			.loaded & {
				opacity: 1;
				transform: scale(1);
			}
		}
		@keyframes blink {
			0% {
				opacity: 0;
			}
			20% {
				opacity: 0.4;
			}
			40% {
				opacity: 0.2;
			}
			60% {
				opacity: 0.6;
			}
			70% {
				opacity: 0.4;
			}
			80% {
				opacity: 0.6;
			}
			90% {
				opacity: 0.8;
			}
			95% {
				opacity: 0.6;
			}
			98% {
				opacity: 0.8;
			}
			100% {
				opacity: 1;
			}
		}
		@media (any-hover: none) {
			&:nth-child(2) {
				transform: translateY(5%);
			}
			&:nth-child(3) {
				transform: translateY(10%);
			}
			&:nth-child(4) {
				transform: translateY(15%);
			}
		}
	}
}
//====================================================================
.marquee {
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0.2;
	overflow: hidden;
	// .marquee__body
	&__body {
		width: 100vw;
		height: 100%;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		// column-gap: rem(100);
		will-change: transform;
		animation: name 15s infinite linear;
		@keyframes name {
			0% {
				transform: translate3d(0, 0, 0);
			}
			100% {
				transform: translate3d(calc(-100vw - rem(200)), 0, 0);
			}
		}
		@media (max-width: $tablet) {
			width: 200vw;
			@keyframes name {
				0% {
					transform: translate3d(0, 0, 0);
				}
				100% {
					transform: translate3d(calc(-200vw - rem(150)), 0, 0);
				}
			}
		}
		._popup-open-purple &{
			animation-play-state: paused;
		}
	}
	// .marquee__items
	&__items {
		position: relative;
		margin-right: rem(200);
		width: 100vw;
		@media (max-width: $tablet) {
			width: 200vw;
			margin-right: rem(150);
		}
		// &:first-child{
		// 	background-color: #77608d;
		// }
		// &:last-child{
		// 	background-color: #794f45;
		// }
	}
	// .marquee__item
	&__item {
		position: absolute;
		&:first-child {
			transform: rotate(-20deg);
			left: 0;
			top: math.div(165, 1080) * 100%;
			width: math.div(360, 1920) * 100%;
			min-width: rem(300);
			@media (max-width: $tablet) {
				min-width: unset;
				// width: math.div(360, 1000) * 100%;
				width: rem(200);
			}
		}
		&:nth-child(2) {
			left: math.div(570, 1920) * 100%;
			bottom: -(math.div(55, 1080) * 100%);
			transform: rotate(20deg);
			width: math.div(260, 1920) * 100%;
			min-width: rem(200);
			@media (max-width: $tablet) {
				// width: math.div(260, 1000) * 100%;
				width: rem(150);
				min-width: unset;
			}
		}
		&:nth-child(3) {
			right: (math.div(450, 1920) * 100%);
			top: -(math.div(143, 1080) * 100%);
			transform: rotate(30deg);
			width: math.div(360, 1920) * 100%;
			min-width: rem(300);
			@media (max-width: $tablet) {
				// width: math.div(360, 1000) * 100%;
				width: rem(200);
				min-width: unset;
			}
		}
		&:nth-child(4) {
			right: -(math.div(10, 1920) * 100%);
			bottom: (math.div(112, 1080) * 100%);
			transform: rotate(-10deg);
			width: math.div(236, 1920) * 100%;
			min-width: rem(260);
			@media (max-width: $tablet) {
				// width: math.div(236, 1000) * 100%;
				width: rem(190);
				min-width: unset;
			}
		}
	}
	// .marquee__image
	&__image {
		img {
			max-width: 100%;
		}
	}
}
