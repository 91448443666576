//<BUTTONS (a.btn+tab)>
// Снипет (HTML): btn

.button {
	display: inline-flex;
	padding: 0px 0px;
	min-width: rem(324);
	min-height: rem(55);
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: rem(21);
	line-height: math.div(25, 21);
	font-weight: 600;
	padding: rem(5) rem(10);
	&_border {
		position: relative;
		border: rem(2) solid;
		border-image-slice: 1;
		border-width: rem(2);
		overflow: hidden;
		transition: all 0.2s ease 0s;
		border-image-source: linear-gradient(105.18deg, #e532f2 0%, #75fbf5 100%);
		span {
			position: relative;
			z-index: 2;
		}
		&::after {
			content: "";
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: linear-gradient(105.18deg, #e532f2 0%, #75fbf5 100%);
			opacity: 0;
			visibility: hidden;
			transition: all 0.3s ease 0s;
		}
		@media (any-hover: hover) {
			&:hover {
				color:#fff;
				&::after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	@media (any-hover: hover) {
		&:hover {
		}
	}
	@media (max-width: $mobile) {
		font-size: rem(18);
		min-height: rem(42);
		min-width: rem(192);
	}
}

.button-shadow {
	@include adaptiveValue("height", 58, 44);
	@include adaptiveValue("font-size", 21, 16);
	color: #fff;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 600;
	box-shadow: 4px -4px 0px #e532f2, -4px 4px 0px #75fbf5;
	transition: box-shadow 0.2s ease 0s;
	@media (max-width: $mobile) {
		box-shadow: 3px -3px 0px #e532f2, -3px 3px 0px #75fbf5;
	}
	@media (any-hover: hover) {
		&:hover {
			box-shadow: 7px -7px 0px #e532f2, -7px 7px 0px #75fbf5;
		}
	}
}

.title-switch {
	color: #fff;
}
